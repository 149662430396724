import { DropdownMenu as DropdownMenuPrimitive } from '@radix-ui/themes'
import React from 'react'
import { cn } from '#app/utils/misc'

const DropdownMenu = DropdownMenuPrimitive.Root

const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger

const DropdownMenuTriggerIcon = DropdownMenuPrimitive.TriggerIcon

const DropdownMenuContent = React.forwardRef<
	React.ElementRef<typeof DropdownMenuPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, ...props }, ref) => {
	return (
		<DropdownMenuPrimitive.Content
			ref={ref}
			className={cn('w-[200px]', className)}
			{...props}
		/>
	)
})
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName

const DropdownMenuItem = DropdownMenuPrimitive.Item

const DropdownMenuItemRed = React.forwardRef<
	React.ElementRef<typeof DropdownMenuPrimitive.Item>,
	React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item>
>(({ className, ...props }, ref) => {
	return <DropdownMenuItem ref={ref} color="red" {...props} />
})
DropdownMenuItemRed.displayName = `Erroroneous ${DropdownMenuPrimitive.Item.displayName}`

const DropdownMenuCheckboxItem = DropdownMenuPrimitive.CheckboxItem

const DropdownMenuSeparator = DropdownMenuPrimitive.Separator

const DropdownMenuSub = DropdownMenuPrimitive.Sub
const DropdownMenuSubTrigger = DropdownMenuPrimitive.SubTrigger

export {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuTriggerIcon,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuCheckboxItem,
	DropdownMenuItemRed,
	DropdownMenuSeparator,
	DropdownMenuSub,
	DropdownMenuSubTrigger,
}
